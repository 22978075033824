import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import logo from '../assets/logo2.png';
import profileUser from '../assets/profile-user.png';
import { useAuth } from '../hooks/useAuth';
import './MultipleChatStyle.css';

const MultipleChat = () => {
    const { currentUser } = useAuth();
    const user_id = currentUser.uid;
    const chatEndRef = useRef(null);
    const [message, setMessage] = useState('');
    const textareaRef = useRef(null);
    const [maxLength, setMaxLength] = useState(150);
    const [maxK, setMaxK] = useState(2);
    const [systemPrompt, setSystemPrompt] = useState('You Are Metamind');
    const [summary, setSummary] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [editingIndex, setEditingIndex] = useState(null);
    const [editedMessage, setEditedMessage] = useState('');
    const [assistantCount, setAssistantCount] = useState(0);
    const [lastUserIndex, setLastUserIndex] = useState(-1);

    const sendMessage = async () => {
        if (!message.trim()) return;

        const promptToSend = systemPrompt.trim() ? systemPrompt : 'You are metamindful';
        const prompt = { "role": 'system', "content": promptToSend };
        const userPrompt = { "role": 'user', "content": message };
        const updatedChatHistory = [prompt, ...chatHistory, userPrompt];
        setChatHistory(prev => [...prev, userPrompt]);
        setMessage('');
        setError(false);
        setLoading(true);

        const dataToSend = {
            "messageTime": new Date().toISOString(),
            "ID": user_id,
            "conversation": updatedChatHistory,
            "max_token": maxLength,
            "k": maxK,
        };

        try {
         //  const response = await axios.post('/generate', dataToSend);
            const response = await axios.post('https://app.metamindful.ai:4000/generate', dataToSend);

            if (response.data && Array.isArray(response.data.response)) {
                setChatHistory(prev => [
                    ...prev,
                    ...response.data.response.map(content => ({ role: 'assistant', content }))
                ]);
            } else {
                console.error("Unexpected response format:", response.data);
                setError('Unexpected response format. Please try again.');
            }
        } catch (error) {
            console.error("Error sending message:", error);
            setError('Failed to send message. Please try again.');
        } finally {
            setLoading(false);
        }

        textareaRef.current.style.height = "60px";
    };

    const downloadChatAsTxt = () => {
        const chatContent = chatHistory
            .map(entry => `${entry.role === 'user' ? 'You' : 'Assistant'}: ${entry.content}`)
            .join('\n');
        const blob = new Blob([chatContent], { type: 'text/plain' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'chat_history.txt';
        link.click();
    };

    useEffect(() => {
        chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [chatHistory]);

    const handleSystemPromptChange = (e) => setSystemPrompt(e.target.value);

    const handleSubmit = (e) => {

        const showDeleteButton = chatHistory.some((message, i) => {

            const isAssistant = message.role === 'assistant';

            // Find the index of the last user message
            const lastUserIndex = chatHistory.length - 1 - [...chatHistory].reverse().findIndex((msg) => msg.role === 'user');

            // Check if the assistant message is linked to the last user
            const isLinkedToLastUser = i > lastUserIndex;

            // Calculate assistant messages linked to the last user
            const assistantCountForLastUser = chatHistory.slice(lastUserIndex + 1).filter((msg) => msg.role === 'assistant').length;

            // Return true if:
            // - The message is an assistant
            // - It is linked to the last user
            // - There are multiple assistant messages linked to the last user
            return isAssistant && isLinkedToLastUser && assistantCountForLastUser > 1;
        });
        e.preventDefault();
        if (!showDeleteButton) {
        sendMessage();}
        else{
            alert('kindly select 1 resposne');
        }
    };

    const handleRangeChange = (event) => setMaxLength(event.target.value);
    const handleKChange = (event) => setMaxK(event.target.value);

    // const handleDeleteResponse = (index) => {
    //     setChatHistory((prevChatHistory) => prevChatHistory.filter((_, i) => i !== index));
    // };

    const handleEditClick = (index, content) => {
        setEditingIndex(index);
        setEditedMessage(content);
    };

    const handleSaveEdit = () => {
        setChatHistory((prevChatHistory) =>
            prevChatHistory.map((message, index) =>
                index === editingIndex
                    ? { ...message, content: editedMessage }
                    : message
            )
        );
        setEditingIndex(null);
        setEditedMessage('');
    };
    const regenerateResponse = async (index) => {
        const selectedMessage = chatHistory[index];

        if (selectedMessage.role !== 'assistant') {
            console.error('Cannot regenerate response for a non-assistant message.');
            return;
        }

        const userPromptIndex = chatHistory.findIndex(
            (message, i) => message.role === 'user' && i < index
        );

        if (userPromptIndex === -1) {
            console.error('No linked user message found for this assistant message.');
            return;
        }

        const userPrompt = chatHistory[userPromptIndex];

        const dataToSend = {
            "messageTime": new Date().toISOString(),
            "ID": user_id,
            "conversation": [userPrompt], // Send only the relevant user prompt
            "max_token": maxLength,
            "k": maxK,
        };

        setLoading(true);
        try {

         //   const response = await axios.post('/completion', dataToSend);
              const response = await axios.post('https://app.metamindful.ai:4000/completion', dataToSend);

            if (response.data ) {
                setEditingIndex(null);

                const regeneratedMessages = response.data.response;

                // Append regenerated messages to the selected assistant message
                // setChatHistory((prevChatHistory) => {
                //     const updatedHistory = [...prevChatHistory];
                //     updatedHistory.splice(index + 1, 0, ...regeneratedMessages); // Insert after the current assistant message
                //     return updatedHistory;
                // });

                setChatHistory((prevChatHistory) =>
                    prevChatHistory.map((message, i) =>
                        i === index
                            ? {
                                ...message,
                                content: message.content + regeneratedMessages, // Append regenerated data only
                            }
                            : message
                    )
                );

            } else {
                console.error('Unexpected response format:', response.data);
                setError('Unexpected response format. Please try again.');
            }
        } catch (error) {
            console.error('Error regenerating response:', error);
            setError('Failed to regenerate response. Please try again.');
        } finally {
            setLoading(false);
        }
    };
    const handleCancelEdit = () => {
        setEditingIndex(null);
        setEditedMessage('');
    };

    const handleInputChange = (e) => {
        setMessage(e.target.value);
        textareaRef.current.style.height = "auto";
        const scrollHeight = textareaRef.current.scrollHeight;
        const height = Math.min(scrollHeight, 10 * 30);
        textareaRef.current.style.height = height < 60 ? `60px` : `${height}px`;
    };
    const handleDeleteResponse = (index) => {
        // Logic to delete the assistant message and decrement the count
        // const updatedChatHistory = chatHistory.filter((_, i) => i !== index);
        // setChatHistory(updatedChatHistory);
             setChatHistory((prevChatHistory) => prevChatHistory.filter((_, i) => i !== index));

        // Check if the deleted message is the last assistant message linked to the current user
        if (chatHistory[index]?.role === 'assistant') {
            setAssistantCount((prevCount) => Math.max(prevCount - 1, 0));
        }
    };

    useEffect(() => {
        // Calculate assistant count and track the last user message index
        let count = 0;
        let lastUserIdx = -1;

        for (let i = chatHistory.length - 1; i >= 0; i--) {
            if (chatHistory[i].role === 'user') {
                lastUserIdx = i;
                break; // Stop when the most recent user message is found
            }
            if (chatHistory[i].role === 'assistant') {
                count++;
            }
        }

        setAssistantCount(count);
        setLastUserIndex(lastUserIdx);
    }, [chatHistory]);
    return (
        <>
            <div className="chat__container">
                <div className="chat__wrapper">
                    <div className="header__section">
                        <input
                            type="text"
                            placeholder="System Prompt"
                            className="form-control prompt"
                            value={systemPrompt}
                            onChange={handleSystemPromptChange}
                        />
                        <button className="btn btn-info pdf-btn" onClick={downloadChatAsTxt}>Download Text</button>
                    </div>
                    <div>
                        <label htmlFor="customRange" className="form-label">Max Length: {maxLength}</label>
                        <input
                            type="range"
                            className="form-range"
                            id="customRange"
                            min="0"
                            max="1000"
                            value={maxLength}
                            onChange={handleRangeChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="kRange" className="form-label">K Value: {maxK}</label>
                        <input
                            type="range"
                            className="form-range"
                            id="kRange"
                            min="1"
                            max="10"
                            value={maxK}
                            onChange={handleKChange}
                        />
                    </div>
                    {error ? (
                        <div className="error">{error}</div>
                    ) : (
                        <>
                            <div className="question_answer__wrapper__chatbot">
                                {chatHistory.map((message, i) => {
                                    const isAssistant = message.role === 'assistant';

                                    // Find the index of the last user message
                                    const lastUserIndex = chatHistory.length - 1 - [...chatHistory].reverse().findIndex((msg) => msg.role === 'user');

                                    // Check if the assistant message is linked to the last user
                                    const isLinkedToLastUser = i > lastUserIndex;

                                    // Calculate assistant messages linked to the last user
                                    const assistantCountForLastUser = chatHistory.slice(lastUserIndex + 1).filter((msg) => msg.role === 'assistant').length;

                                    // Show delete button only if:
                                    // - The message is an assistant
                                    // - It is linked to the last user
                                    // - There are multiple assistant messages linked to the last user
                                    const showDeleteButton = isAssistant && isLinkedToLastUser && assistantCountForLastUser > 1;

                                    return (
                                        <div className="single__question__answer" key={i}>
                                            {/* User Messages */}
                                            {message.role === 'user' && (
                                                <div className="question_user">
                                                    <div
                                                        className="question__user"
                                                        onDoubleClick={() => handleEditClick(i, message.content)}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        <div className="left_user_info" style={{ color: 'black' }}>
                                                            <img
                                                                style={{ width: '35px' }}
                                                                src={profileUser}
                                                                alt="avatar"
                                                            />
                                                            {editingIndex === i ? (
                                                                <textarea
                                                                    value={editedMessage}
                                                                    onChange={(e) => setEditedMessage(e.target.value)}
                                                                    className="form-control"
                                                                    style={{
                                                                        borderRadius: '10px',
                                                                        background: '#ffffff',
                                                                        padding: '10px',
                                                                        width: '100%',
                                                                    }}
                                                                />
                                                            ) : (
                                                                <>
                                                                    You: {message.content}{' '}

                                                                </>
                                                            )}
                                                            {editingIndex === i && (
                                                                <div style={{ marginTop: '10px', textAlign: 'right', display: 'flex' }}>
                                                                    <button
                                                                        className="btn btn-secondary"
                                                                        style={{ marginLeft: '10px' }}
                                                                        onClick={handleCancelEdit}
                                                                    >
                                                                        <i className="fa fa-times"></i>
                                                                    </button>
                                                                    <button
                                                                        className="btn btn-success"
                                                                        style={{ marginLeft: '10px' }}
                                                                        onClick={() => handleSaveEdit(i)}
                                                                    >
                                                                        <i className="fa fa-check"></i>
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {/* Assistant Messages */}
                                            {isAssistant && (
                                                <div className="answer__area">
                                                    <div className="thumbnail">
                                                        <img
                                                            style={{ width: '40px !important' }}
                                                            src={logo}
                                                            alt="avatar"
                                                        />
                                                    </div>
                                                    <div
                                                        className="answer_main__wrapper"
                                                        onDoubleClick={() => handleEditClick(i, message.content)}
                                                        style={{
                                                            background: '#E2FFC7',
                                                            padding: '10px',
                                                            marginTop: '0px',
                                                            borderRadius: '10px',
                                                            width: '100%',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        {/* Editing Mode */}
                                                        {editingIndex === i ? (
                                                            <textarea
                                                                value={editedMessage}
                                                                onChange={(e) => setEditedMessage(e.target.value)}
                                                                className="form-control"
                                                                style={{
                                                                    borderRadius: '10px',
                                                                    background: '#ffffff',
                                                                    padding: '10px',
                                                                    width: '100%',
                                                                }}
                                                            />
                                                        ) : (
                                                            // Display Mode
                                                            <p className="disc">
                                                                {message.content}{' '}
                                                                {showDeleteButton && (
                                                                    <i
                                                                        className="fa fa-trash"
                                                                        aria-hidden="true"
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                            color: 'red',
                                                                        }}
                                                                        onClick={() => handleDeleteResponse(i)}
                                                                    ></i>
                                                                )}
                                                            </p>
                                                        )}

                                                        {/* Save and Cancel Buttons for Editing */}
                                                        {editingIndex === i && (
                                                            <div style={{ marginTop: '10px', textAlign: 'right', display: 'flex' }}>
                                                                <button
                                                                    className="btn btn-secondary"
                                                                    style={{ marginLeft: '10px' }}
                                                                    onClick={handleCancelEdit}
                                                                >
                                                                    <i className="fa fa-times"></i>
                                                                </button>
                                                                <button
                                                                    className="btn btn-success"
                                                                    style={{ marginLeft: '10px' }}
                                                                    onClick={() => handleSaveEdit(i)}
                                                                >
                                                                    <i className="fa fa-check"></i>
                                                                </button>

                                                                {!showDeleteButton && (
                                                                    <button
                                                                        className="btn btn-success"
                                                                        style={{ marginLeft: '10px' }}
                                                                        onClick={() => regenerateResponse(i)}
                                                                    >
                                                                        <i className="fa fa-refresh"></i>
                                                                    </button>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    );
                                })}
                                <div ref={chatEndRef}></div>
                                {loading && (
                                    <div className="answer__area">
                                        <div className="answer_main__wrapper" style={{ background: '#E2FFC7', padding: '10px', marginTop: '0px', borderRadius: '10px' }}>
                                            <p className="disc">Loading</p>
                                        </div>
                                    </div>
                                )}
                                {summary.trim() && (
                                    <div className="summary__section">
                                        <h3>Summary:</h3>
                                        <p>{summary}</p>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    <form onSubmit={handleSubmit} className="search-form">
                        <div className="mb-3" style={{ position: 'relative' }}>
                            <textarea
                                ref={textareaRef}
                                className="form-control"
                                style={{
                                    borderRadius: '20px',
                                    background: '#E2FFC7',
                                    width: '100%',
                                    paddingRight: '40px',
                                    resize: 'none',
                                    overflow: 'hidden',
                                    height: '60px',
                                }}
                                id="messageTextarea"
                                placeholder="Ask here"
                                rows="1"
                                value={message}
                                onChange={handleInputChange}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && !e.shiftKey) {
                                        e.preventDefault(); // Prevent default newline behavior
                                        handleSubmit(e);
                                    }
                                }}
                            ></textarea>
                            <button
                                type="submit"
                                className="send-button"
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    width: '40px',
                                    right: '10px',
                                    transform: 'translateY(-50%)',
                                    border: 'none',
                                    backgroundColor: 'transparent',
                                    cursor: 'pointer',
                                    fontSize: '2.5rem',
                                    color: message.trim() ? 'black' : '#D7D7D7',
                                }}
                                disabled={!message.trim()}
                            >
                                <i className="fa fa-paper-plane" aria-hidden="true"></i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default MultipleChat;
